/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
@import "~bootstrap/scss/bootstrap";
//
/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "theme/helpers.scss";
@import "theme/modals.scss";
@import "theme/breakpoints.scss";
@import "app/core/directives/tooltip/tooltip.directive.scss";
@import "app/core/directives/info-tooltip/info-tooltip.directive.scss";

body {
    color: var(--ion-color-dark);
}

// .feedback-modal {
//     box-shadow: none;
//     background-color: transparent;
// }

// .feedback-modal .modal-wrapper{
//     box-shadow: none;
//     background-color: transparent;
//     height: 400px;
//     max-width: 400px;
// }

.highcharts-credits {
    display: none;
}

person-avatar {
    margin-inline-end: 0;
}

.my-custom-class .popover-wrapper {
    box-shadow: none;
    background-color: transparent;
    height: 235px;
    max-width: 500px;
    width: 500px;
}

.my-custom-class .popover-content {
    box-shadow: none;
    background-color: transparent;
    height: 235px;
    max-width: 500px;
    width: 500px;
}

ion-avatar {
    border-radius: 50%;
    // border: 4px solid transparent;
    border: double 2px transparent;
    border-radius: 50%;
    background-image: linear-gradient(white, white),
        radial-gradient(circle at top left, #ffcb04 20%, #8ac440);
    background-origin: border-box;
    background-clip: content-box, border-box;
}

ion-modal.auto-height {
    &.bottom {
        align-items: flex-end;
    }

    --height: auto;

    .ion-page {
        position: relative;
        display: block;
        contain: content;

        .inner-content {
            max-height: 80vh;
            overflow: auto;
            padding: 10px;
        }
    }
}

div.chart-container {
    max-width: 100%;
    width: 100%;
    display: block;
}

@media (max-width: 575px) {
    ion-header ion-title {
        font-size: 0.95em;
    }
}

.alert-wrapper.ion-overlay-wrapper.sc-ion-alert-md {
    min-width: 500px;
}

.alert-radio-label,
.alert-checkbox-label {
    white-space: normal !important;
}

ion-icon {
    pointer-events: none;
}

ion-skeleton-text {
    line-height: 25px;
}

ion-item.selected {
    background-color: #8cff006c;
}

ion-toggle {
    height: 20px;
    width: 40px;

    --handle-width: 20px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px var(--ion-color-prestes-dark) inset !important;
    -webkit-text-fill-color: var(--ion-color-dark) !important;
}

@-webkit-keyframes autofill {

    0%,
    100% {
        color: rgba(255, 255, 255, 0);
        background: transparent;
    }
}

input:-webkit-autofill {
    -webkit-animation-delay: 1s;
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both;
}

ion-toolbar.toolbar-title-default {
    background: var(--ion-color-prestes-green) !important;
    --background: var(--ion-color-prestes-green) !important;
    background-color: var(--ion-color-prestes-green) !important;
    --background-color: var(--ion-color-prestes-green) !important;
    color: white !important;
    --color: white !important;
}

.toolbar-background {
    background-color: var(--ion-color-prestes-green) !important;
    --background-color: var(--ion-color-prestes-green) !important;
    color: white !important;
    --color: white !important;
}

.cursor-pointer {
    cursor: pointer;
}

.mobile-only {
    @media (min-width: var(--breakpoint)) {
        display: none;
    }
}

.ionic-selectable-modal {
    border: 10px solid var(--ion-color-prestes-green);
    background-color: var(--ion-color-prestes-dark);
    border-radius: 0px;
    box-shadow: none !important;

    &.ion-page {
        overflow: initial;
    }
}

.sc-ion-modal-md-h {
    --overflow: initial !important;
}

.ionic-selectable-modal::after {
    content: "";
    background-color: var(--ion-color-prestes-dark);
    width: 50px;
    height: 50px;
    position: absolute;
    top: 100%;
    left: -10px;
    border: 10px solid var(--ion-color-prestes-green);
    border-top: none;
    border-bottom-right-radius: 100%;
}

.ionic-selectable-modal ion-content {
    --offset-bottom: auto !important;
    --overflow: auto;
    overflow: auto;

    &::-webkit-scrollbar {
        width: 7px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background: rgba(var(--ion-color-prestes-green-rgb));
        border-radius: 12px;
        border: 2px solid transparent;
        background-clip: padding-box;
        transition: 1s;
    }

    &::-webkit-scrollbar-thumb:hover {
        border: 0;
        transition: 1s;
    }

    .inner-scroll {
        scrollbar-width: thin;
    }
}

.ionic-selectable-modal ion-title {
    margin-top: -5px;
}

.ionic-selectable-modal ion-buttons {
    margin-top: -5px;
}

.ionic-selectable-modal ion-button {
    background-color: #84e115;
}

@media (max-width: 767px) {
    .ionic-selectable-modal {
        border: none;
    }

    .ionic-selectable-modal::after {
        content: none;
    }

    .ionic-selectable-modal::before {
        content: none;
    }
}

.table {
    color: var(--ion-color-dark)!important;
}

.approvers:last-of-type {
    approver-avatar person-avatar>ion-avatar::after {
        content: none !important;
    }
}

ion-toast {
    --height: 10vh;
    --border-color: var(--ion-color-prestes-dark);
    font-size: 1em;
}

.custom-modal {
    &.bottom {
        align-items: flex-end;
    }

    --height: auto;

    .ion-page {
        position: relative;
        display: block;
        contain: content;

        .inner-content {
            max-height: 90vh;
            overflow: auto;
            padding: 10px;
            background-color: var(--ion-color-light);
        }
    }
  }

// ion-icon.prestes-green {
//     background: var(--ion-color-prestes-green);
//     position: absolute;
//     left: 40px;
//     bottom: .2em;
//     padding: 0.2em;
//     fill: #000!important;
//     border-radius: 100%;
//     font-weight: bold;
// }

// ion-icon.warning {
//     background: var(--ion-color-warning);
//     position: absolute;
//     left: 40px;
//     bottom: .2em;
//     padding: 0.2em;
//     fill: #000!important;
//     border-radius: 100%;
// }

// ion-icon.danger {
//     background: var(--ion-color-danger);
//     position: absolute;
//     left: 40px;
//     bottom: .2em;
//     padding: 0.2em;
//     fill: #000!important;
//     border-radius: 100%;
// }

// ion-icon.medium {
//     background: var(--ion-color-medium);
//     position: absolute;
//     left: 40px;
//     bottom: .2em;
//     padding: 0.2em;
//     fill: #000!important;
//     border-radius: 100%;
// }

app-tooltip {
    margin: 0 10px;
}

.label {
    display: flex!important;
    white-space: pre-line!important;
    align-items: center;
}

asset-summary div.prestes-card {
    margin-top: 10px;
}

ngb-datepicker {
    &.dropdown-menu {
        background-color: var(--ion-color-light);
        border-radius: 0.25rem;
    }

    .ngb-dp-header {
        background-color: var(--ion-color-light);
    }

    .ngb-dp-arrow {
        .btn-link {
            color: var(--ion-color-prestes-green);
        }
    }

    .btn-light {
        color: var(--ion-color-dark);
    }

    .bg-primary {
        background-color: var(--ion-color-prestes-green)!important;
    }

    .ngb-dp-week {
        background-color: var(--ion-color-light);

        .ngb-dp-weekday {
            color: var(--ion-color-prestes-green);
        }
    }
}

ngb-pagination li.page-item {
    .page-link {
        color: var(--ion-color-prestes-green);
        background-color: rgba(0,0,0,0);
        border: none;

        &:hover {
            background-color: var(--ion-color-prestes-green);
            color: #ffffff;
        }
    }
    &.active {
        .page-link {
            background-color: var(--ion-color-prestes-green);
            color: #ffffff;
            border-color: var(--ion-color-prestes-green);
        }
    }

    &.disabled {
        .page-link {
            background-color: rgba(0,0,0,0);
            color: #ffffff;
        }
    }
}

